import {
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import { FiArrowUpCircle } from 'react-icons/fi';
import {
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export default function MessageTextBox(props) {
  const {
    error,
    disabled,
    onSubmit,
  } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState(DEFAULT_INPUT_VALUE);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    if (!value?.trim()) {
      return;
    }

    onSubmit(value.trim());
    setValue(DEFAULT_INPUT_VALUE);
  }, [value, onSubmit, setValue]);

  const handleChange = useCallback((event) => {
    setValue(event.target.value);
  }, [setValue]);

  const handleKeyDown = useCallback((event) => {
    if (event.which === 13 && !event.shiftKey) {
      if (!event.repeat) {
        handleSubmit(event);
      }

      event.preventDefault(); // Prevents the addition of a new line in the text field
    }
  }, [handleSubmit]);

  return (
    <form onSubmit={handleSubmit}>
      <Paper
        sx={{
          display: 'flex',
          borderColor: 'primary.main',
          borderWidth: 2,
        }}
        variant="outlined"
      >
        <TextField
          disabled={disabled || error}
          fullWidth
          hiddenLabel
          maxLength={500}
          maxRows={5}
          multiline
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={t('components.Chat.MessageBox.label')}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '0 !important',
            },
          }}
          value={value}
          variant="outlined"
        />
        <Tooltip placement="left" title={t('components.Chat.MessageBox.sendMessage')}>
          <IconButton
            color="primary"
            disabled={disabled || !value?.trim()}
            sx={{ ml: 1, mr: 1 }}
            type="submit"
          >
            <FiArrowUpCircle />
          </IconButton>
        </Tooltip>
      </Paper>
    </form>
  );
}

const DEFAULT_INPUT_VALUE = '';
