import { FiMessageSquare } from 'react-icons/fi';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function NoChatAvailable({ onClose }) {
  const { t } = useTranslation();

  return (
    <Box
      alignItems="center"
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      p={3}
    >
      <Box sx={{ opacity: 0.5 }}>
        <FiMessageSquare size={32} />
      </Box>
      <Box mt={3}>
        <Typography
          textAlign="center"
          variant="h6"
        >
          {t('components.Chat.NoChatAvailable.chatNotAvailable')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('components.Chat.NoChatAvailable.pleasSelectEvent') }}
          textAlign="center"
          variant="body2"
        />
      </Box>
      <Box mt={3}>
        <Button
          onClick={onClose}
          variant="outlined"
        >
          {t('shared.close')}
        </Button>
      </Box>
    </Box>
  );
}
