/* eslint-disable max-len */
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Chip,
  Button,
  Alert,
} from '@mui/material';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import {
  FiMoreVertical,
  FiX,
  FiClock,
  FiChevronRight,
  FiArrowLeft,
} from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa6';
import { useRouter } from 'next/router';
import {
  LuMessageCirclePlus,
  LuMessageCircleX,
} from 'react-icons/lu';
import { useTenant } from '@lib/hooks';
import MessageList from './MessageList';
import MessageTextBox from './MessageTextBox';

export default function ChatWindowContent({ onClose }) {
  const { tenant, hasTenantModule } = useTenant();
  const { t, i18n } = useTranslation();
  const firstRender = useRef(true);
  const [threadId, setThreadId] = useState(null);
  const [currentThreadOpen, setCurrentThreadOpen] = useState(false);
  const [showRecentThreads, setShowRecentThreads] = useState(false);
  const [endChatMessageOpen, setEndChatMessageOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { eventid: eventId } = router.query;

  const {
    data: initialMessagesData, loading: loading2, error, refetch: refetchInitial,
  } = useQuery(FETCH_INITIAL_MESSAGES, {
    variables: {
      threadId,
      eventId: eventId ?? -1,
    },
  });
  const { data: threadsData, loading: loading3, refetch: threadsRefetch } = useQuery(FETCH_EVENT_THREADS, {
    variables: {
      eventId: eventId ?? -1,
    },
  });
  const [fetchThreadMessages, { data, refetch }] = useLazyQuery(FETCH_THREAD_MESSAGE, {
    pollInterval: 500,
  });

  const [createThread] = useMutation(CREATE_THREAD);
  const [createMessage, { loading }] = useMutation(CREATE_MESSAGE);
  const [rateThreadResponse] = useMutation(RATE_THREAD_RESPONSE);
  const [retryThreadMessage, { loading: retryLoading }] = useMutation(RETRY_THREAD_MESSAGE);
  const [endThread] = useMutation(END_THREAD);

  const assistantConfig = hasTenantModule('ComponentTenantModuleAiAgent')
    ? tenant.attributes.modules.find((tm) => tm.__typename === 'ComponentTenantModuleAiAgent')
    : null;
  const threads = threadsData?.assistantThreads.data ?? [];
  const currentThread = initialMessagesData?.assistantThread.data;
  const messages = data?.messages.data?.length > 0
    ? data.messages.data
    : initialMessagesData?.assistantInitialMessages.data ?? [];

  useEffect(() => {
    if (firstRender.current) {
      const existingThread = initialMessagesData?.assistantThread.data;

      if (existingThread?.id && !existingThread?.attributes.isDeleted) {
        firstRender.current = false;
        setThreadId(existingThread.id);
        setCurrentThreadOpen(!existingThread.attributes.isDeleted);
        fetchThreadMessages({ variables: { threadId: existingThread.id } });
      }
    }

    return () => {
      refetchInitial();
    };
  }, [
    setThreadId,
    initialMessagesData,
    fetchThreadMessages,
  ]);

  const handleSubmitMessage = useCallback(async (value) => {
    try {
      let localThreadId = threadId;

      if (!localThreadId) {
        // Create thread first & then post the message
        const { data: threadData2 } = await createThread({
          variables: {
            data: {
              event: eventId,
              content: value,
            },
          },
        });
        localThreadId = threadData2.assistantCreateThread.data.id;
        setThreadId(localThreadId);
      } else {
        await createMessage({
          variables: {
            threadId: localThreadId,
            data: {
              content: value,
            },
          },
        });
      }
      await fetchThreadMessages({ variables: { threadId: localThreadId } });
      await threadsRefetch();
    } catch (submitError) {
      enqueueSnackbar(submitError.message, { variant: 'error' });
    }
  }, [
    eventId,
    threadId,
    createMessage,
    createThread,
    enqueueSnackbar,
    fetchThreadMessages,
  ]);

  const handleRateThreadResponse = useCallback(async (messageId, positive) => {
    try {
      await rateThreadResponse({
        variables: {
          threadId,
          messageId,
          positive,
        },
      });
      enqueueSnackbar(positive ? 'Liked' : 'Disliked', {
        variant: 'success',
      });
      await fetchThreadMessages({ variables: { threadId } });
    } catch (rateError) {
      enqueueSnackbar(rateError.message, { variant: 'error' });
    }
  }, [
    threadId,
    rateThreadResponse,
    enqueueSnackbar,
    fetchThreadMessages,
  ]);

  const handleRetryThreadMessage = useCallback(async (messageId) => {
    try {
      await retryThreadMessage({
        variables: {
          threadId,
          messageId,
        },
      });
      enqueueSnackbar('Retried', {
        variant: 'success',
      });
      await fetchThreadMessages({ variables: { threadId } });
    } catch (retryError) {
      enqueueSnackbar(retryError.message, { variant: 'error' });
    }
  }, [
    threadId,
    retryThreadMessage,
    enqueueSnackbar,
    fetchThreadMessages,
  ]);

  useEffect(() => {
    fetchThreadMessages({ variables: { threadId } });
  }, [threadId]);

  const startNewChat = async () => {
    setCurrentThreadOpen(true);
    setThreadId(null);
    await fetchThreadMessages({ variables: { threadId: null } });
  };

  const isStreaming = messages.length > 0
    ? messages[messages.length - 1]?.attributes?.isStreaming ?? false
    : false;

  /* const handleChangeThread = async (event2) => {
    setThreadId(event2.target.value);
    await fetchThreadMessages({ variables: { threadId: event2.target.value } });
  }; */

  const handleOpenMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMore = () => {
    setAnchorEl(null);
  };

  const handleStartNewChat = async () => {
    setEndChatMessageOpen(false);
    await startNewChat();
    handleCloseMore();
    setShowRecentThreads(false);
  };
  const handleEndChat = async (thId) => {
    if (thId) {
      await endThread({
        variables: {
          threadId: thId,
        },
      });
    }
    await fetchThreadMessages({ variables: { threadId: null } });
    setEndChatMessageOpen(false);
    handleCloseMore();
    setThreadId(null);
    onClose();
  };

  const handleShowRecentChats = async () => {
    await threadsRefetch();
    setEndChatMessageOpen(false);
    setShowRecentThreads(true);
    setAnchorEl(null);
  };

  const handleSelectThread = (thrdId, hasEnded) => {
    setThreadId(thrdId);
    setShowRecentThreads(false);
    setCurrentThreadOpen(!hasEnded);
  };

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      sx={{
        position: 'relative',
        overflowY: 'hidden',
      }}
    >
      <AppBar
        position="static"
      >
        <Toolbar>
          {showRecentThreads ? (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Tooltip
                placement="left"
                title={t('shared.back')}
              >
                <IconButton
                  color="inherit"
                  onClick={() => {
                    setShowRecentThreads(false);
                  }}
                >
                  <FiArrowLeft />
                </IconButton>
              </Tooltip>
              <Typography sx={{ fontWeight: 'bold', lineHeight: '1.2em' }} variant="subtitle2">
                {t('components.Chat.ChatWindowContent.recentChats')}
              </Typography>
              <Tooltip
                placement="left"
                title={t('components.Chat.ChatWindowContent.startNewChat')}
              >
                <IconButton
                  color="inherit"
                  onClick={handleStartNewChat}
                >
                  <LuMessageCirclePlus />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <>
              <Box alignItems="center" display="flex" mr="auto">
                <Avatar
                  src={`/images/chat/avatar/${assistantConfig?.assistantGender || 'female'}/default.svg`}
                  sx={{
                    bgcolor: 'neutral.100',
                  }}
                />
                <Box ml={2}>
                  <Typography sx={{ fontWeight: 'bold', lineHeight: '1.2em' }} variant="subtitle2">
                    {assistantConfig?.assistantName || 'Emily'}
                  </Typography>
                  <Typography
                    sx={{ lineHeight: '1.2em' }}
                    variant="body2"
                  >
                    {isStreaming ? (
                      <em>
                        {t('components.Chat.ChatWindowContent.isStreaming')}
                      </em>
                    ) : (
                      <span>
                        {assistantConfig?.assistantRole || t('components.Chat.ChatWindowContent.aiEventManager')}
                      </span>
                    )}
                  </Typography>
                </Box>
              </Box>
              <IconButton
                aria-controls={open ? 'more-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                color="inherit"
                id="open-more-btn"
                onClick={handleOpenMore}
              >
                <FiMoreVertical />
              </IconButton>
              <Tooltip
                placement="left"
                title={t('components.Chat.ChatWindowContent.closeChat')}
              >
                <IconButton
                  color="inherit"
                  onClick={onClose}
                >
                  <FiX />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        id="more-menu"
        MenuListProps={{
          'aria-labelledby': 'open-more-btn',
        }}
        onClose={handleCloseMore}
        open={open}
        sx={{
          '.MuiMenuItem-root .MuiListItemIcon-root': {
            minWidth: 'unset',
          },
        }}
      >
        <MenuItem
          disabled={error}
          onClick={handleStartNewChat}
        >
          <ListItemIcon>
            <LuMessageCirclePlus size={20} />
          </ListItemIcon>
          <ListItemText>
            {t('components.Chat.ChatWindowContent.startNewChat')}
          </ListItemText>
        </MenuItem>
        <MenuItem
          disabled={!threadId || currentThread?.attributes.isDeleted}
          onClick={() => {
            setEndChatMessageOpen(true);
            handleCloseMore();
          }}
        >
          <ListItemIcon>
            <LuMessageCircleX size={20} />
          </ListItemIcon>
          <ListItemText>
            {t('components.Chat.ChatWindowContent.endChat')}
          </ListItemText>
        </MenuItem>
        <MenuItem
          disabled={error}
          onClick={handleShowRecentChats}
        >
          <ListItemIcon>
            <FiClock size={20} />
          </ListItemIcon>
          <ListItemText>
            {t('components.Chat.ChatWindowContent.viewRecentChats')}
          </ListItemText>
        </MenuItem>
      </Menu>
      {/* <Box
        pt={2}
        px={2}
      >
        <Select
          fullWidth
          items={[
            {
              id: null,
              name: t('components.Chat.startNewChat'),
            },
            ...threads.map((thread) => ({
              id: thread.id,
              name: DateTime.fromISO(thread.attributes.createdAt).setLocale(i18n.language)
                .toLocaleString(DateTime.DATETIME_SHORT),
              customerName: thread.attributes.event.data.attributes.customer,
              location: thread.attributes.event.data.attributes.location.data.attributes[`name_${i18n.language}`],
            })),
          ]}
          label={t('components.Chat.previousMessageThreads')}
          onChange={handleChangeThread}
          renderItem={({ item }) => (
            <MenuItem
              key={item.id}
              value={item.id}
            >
              <Box>
                {item.id ? (
                  <>
                    <Typography
                      sx={{
                        mr: 1,
                      }}
                      variant="body2"
                    >
                      {item.customerName}
                    </Typography>
                    <Typography color="text.disabled" component="div" variant="caption">
                      {item.location}
                      {', '}
                      {item.name}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    sx={{
                      mr: 1,
                    }}
                    variant="body2"
                  >
                    {item.name}
                  </Typography>
                )}
              </Box>
            </MenuItem>
          )}
          size="small"
          value={threadId ?? ''}
        />
      </Box> */}
      {endChatMessageOpen && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
          }}
        >
          <LuMessageCircleX size={20} />
          <Typography sx={{ fontWeight: 'bold', lineHeight: '1.2em', my: 1 }} variant="h6">
            {t('components.Chat.ChatWindowContent.endChat')}
          </Typography>
          <Typography variant="caption">
            {t('components.Chat.ChatWindowContent.wantToEndChat')}
          </Typography>
          <Button
            onClick={() => handleEndChat(threadId)}
            sx={{
              borderRadius: 1,
              mt: 2,
              mb: 1,
            }}
            variant="contained"
          >
            {t('components.Chat.ChatWindowContent.yesEndChat')}
          </Button>
          <Button
            onClick={() => setEndChatMessageOpen(false)}
            sx={{
              borderRadius: 1,
            }}
            variant="outlined"
          >
            {t('shared.cancel')}
          </Button>
        </Box>
      )}
      {showRecentThreads && (
        <List>
          {threads.map((item) => {
            const lastMessage = item.attributes.messages.data.at(-1);
            const hasEnded = item.attributes.isDeleted;

            return (
              <>
                <ListItemButton
                  key={item.id}
                  onClick={() => handleSelectThread(item.id, hasEnded)}
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      flex: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        display: '-webkit-box',
                        '-webkit-box-orient': 'vertical',
                        '-webkit-line-clamp': '1',
                        overflow: 'hidden',
                        fontWeight: '600',
                      }}
                      variant="body2"
                    >
                      {lastMessage.attributes.content}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Typography color="text.disabled" component="div" variant="caption">
                        {lastMessage.attributes.role === 'assistant' && t('components.Chat.ChatWindowContent.aiEventManager')}
                      </Typography>
                      <Typography color="text.disabled" component="div" mx={0.5} variant="caption">•</Typography>
                      <Typography color="text.disabled" component="div" variant="caption">
                        {DateTime.fromISO(item.attributes.updatedAt).toRelative()}
                      </Typography>
                    </Box>
                  </Box>
                  {!hasEnded && <Chip label={t('shared.open')} />}
                  <FiChevronRight />
                </ListItemButton>
                <Divider />
              </>
            );
          })}
        </List>
      )}
      {!showRecentThreads && !endChatMessageOpen && (
        <>
          {error && (
            <Alert severity="error">
              {error.message ?? 'Fehler'}
            </Alert>
          )}
          <MessageList
            assistantConfig={assistantConfig}
            handleRateThreadResponse={handleRateThreadResponse}
            handleRetryThreadMessage={handleRetryThreadMessage}
            items={messages}
            loading={loading || loading2}
            threadId={threadId}
          />
          {(currentThreadOpen || !threadId) ? (
            <Box p={1}>
              <MessageTextBox
                disabled={loading}
                error={error}
                onSubmit={handleSubmitMessage}
                startNewChat={startNewChat}
              />
            </Box>
          ) : (
            <Box
              p={2}
              sx={{
                bgcolor: '#eee',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography color="text.disabled" component="div" variant="subtitle2">
                {t('components.Chat.ChatWindowContent.conversationHasEnded')}
              </Typography>
              <Button
                onClick={startNewChat}
                startIcon={<FaPlus />}
                sx={{
                  borderRadius: 1,
                }}
                variant="outlined"
              >
                {t('components.Chat.ChatWindowContent.startNewChat')}
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

const FETCH_INITIAL_MESSAGES = gql`
  query FetchInitialMessages($threadId: ID, $eventId: ID!) {
    assistantInitialMessages {
      data {
        id
        attributes {
          role
          content
          createdAt
        }
      }
    }
    assistantThread(threadId: $threadId, eventId: $eventId) {
      data {
        id
        attributes {
          isDeleted
        }
      }
    }
  }
`;

const FETCH_EVENT_THREADS = gql`
  query FetchEventThreads($eventId: ID!) {
    assistantThreads(eventId: $eventId) {
      data {
        id
        attributes {
          createdAt
          isDeleted
          updatedAt
          messages(pagination: { limit: 100 }) {
            data {
              id
              attributes {
                content
                role
                createdAt
              }
            }
          }
          event {
            data {
              id
              attributes {
                customer
                location {
                  data {
                    attributes {
                      name_de
                      name_en
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const END_THREAD = gql`
  mutation EndThread($threadId: ID!) {
    updateThread(data: { isDeleted: true }, id: $threadId){
      data {
        id
      }
    }
  }
`;

const FETCH_THREAD_MESSAGE = gql`
  query FetchThreadMessages($threadId: ID) {
    messages (
      sort: ["createdAt:asc"]
      filters: {
        thread: {id: {eq: $threadId}},
        isHidden: {eq: false}
      }
      pagination: {limit: 100}
    ) {
      data {
        id
        attributes {
          role
          content
          createdAt
          userRated
          isStreaming
        }
      }
    }
  }
`;

const CREATE_THREAD = gql`
  mutation CreateThread($data: CreateThreadInput!) {
    assistantCreateThread(data: $data) {
      data {
        id
      }
    }
  }
`;

const CREATE_MESSAGE = gql`
  mutation CreateMessage($threadId: ID!, $data: CreateMessageInput!) {
    assistantCreateMessage(threadId: $threadId, data: $data)
  }
`;

const RATE_THREAD_RESPONSE = gql`
  mutation RateThreadResponse($threadId: ID!, $messageId: ID!, $positive: Boolean!) {
    assistantRateThreadResponse(threadId: $threadId, messageId: $messageId, positive: $positive)
  }
`;

const RETRY_THREAD_MESSAGE = gql`
  mutation RetryThreadMessage($threadId: ID!, $messageId: ID!) {
    assistantThreadMessageRetry(threadId: $threadId, messageId: $messageId)
  }
`;
