import {
  Box,
  Paper,
  Skeleton,
  Typography,
  IconButton,
  Avatar,
} from '@mui/material';
import { DateTime } from 'luxon';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useSnackbar } from 'notistack';
import { marked } from 'marked';
import {
  BiLike,
  BiDislike,
  BiSolidLike,
  BiSolidDislike,
} from 'react-icons/bi';
import {
  FiRefreshCw,
  FiUser,
} from 'react-icons/fi';
import { LuCopy } from 'react-icons/lu';
import { useSession } from 'next-auth/react';
import { styled } from '@mui/material/styles';

const ShrinkWrapper = styled('div')(() => ({
  width: 'fit-content',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '100%',
}));

export default function MessageList(props) {
  const {
    items,
    loading,
    threadId,
    assistantConfig,
    handleRateThreadResponse,
    handleRetryThreadMessage,
  } = props;
  const { data: session } = useSession();
  const containerRef = useRef();
  const scrollTargetRef = useRef();
  const userScrolling = useRef(false);
  const scrollTimeout2 = useRef();
  const { user } = session ?? {};

  const scrollToBottom = useCallback(() => {
    clearTimeout(scrollTimeout2.current);

    if (userScrolling.current) {
      return;
    }

    scrollTimeout2.current = setTimeout(() => {
      scrollTargetRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'end',
      });
    }, 0);
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(scrollToBottom);
    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [scrollToBottom]);

  useEffect(() => {
    let scrollTimeout = null;
    const handler = () => {
      clearTimeout(scrollTimeout);
      userScrolling.current = true;

      scrollTimeout = setTimeout(() => {
        userScrolling.current = false;
      }, 150);
    };
    containerRef.current.addEventListener('scroll', handler);

    return () => {
      containerRef.current?.removeEventListener('scroll', handler);
    };
  }, []);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      pt={2}
      px={1}
      sx={{
        height: '100%',
        overflowY: 'scroll',
        bgcolor: 'background.paper',
      }}
    >
      <ShrinkWrapper ref={containerRef}>
        {items.map((item, index) => (
          <MessageItem
            key={item.id}
            assistantConfig={assistantConfig}
            date={item.attributes.createdAt}
            handleRateMessage={handleRateThreadResponse}
            handleRetryMessage={handleRetryThreadMessage}
            isLastMessageItem={(items.length - 1) === index}
            isStreaming={item.attributes.isStreaming}
            message={item.attributes.content}
            messageId={item.id}
            role={item.attributes.role}
            threadId={threadId}
            user={user}
            userRated={item.attributes.userRated}
          />
        ))}
        {loading && <MessageLoadingIndicator />}
      </ShrinkWrapper>
      <div ref={scrollTargetRef} id="scroll-target" />
    </Box>
  );
}

function MessageItem(props) {
  const {
    role,
    date,
    message,
    messageId,
    userRated,
    threadId,
    user,
    assistantConfig,
    isStreaming,
    isLastMessageItem,
    handleRateMessage,
    handleRetryMessage,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const flexAlign = role === 'user' ? 'flex-end' : 'flex-start';
  const dateDisplay = DateTime
    .fromISO(date)
    .setLocale('de')
    .toLocaleString(DateTime.DATETIME_SHORT);

  const markedMessage = useMemo(() => marked(message ?? '', {
    smartypants: true,
    breaks: true,
    renderer: CUSTOM_RENDERER,
  }), [message]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(message);
      enqueueSnackbar('Copied to clipboard!', {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Copy to clipboard failed', {
        variant: 'error',
      });
    }
  };

  const avatar = (
    <Box>
      <Avatar
        src={
          role === 'assistant'
            ? `/images/chat/avatar/${assistantConfig.assistantGender || 'female'}/default.svg`
            : user.profileImage?.formats?.small?.url ?? user.profileImage?.url
        }
        sx={{
          width: 24,
          height: 24,
          bgcolor: role === 'assistant' ? 'neutral.100' : undefined,
        }}
      >
        <FiUser />
      </Avatar>
      <Box sx={{ visibility: 'hidden' }}>
        <IconButton size="small">
          <FiRefreshCw />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <Box
      alignSelf={flexAlign}
      sx={MESSAGE_BOX_STYLES}
    >
      {role === 'assistant' && avatar}
      <Box
        ml={role === 'assistant' ? 1 : 0}
        mr={role === 'user' ? 1 : 0}
      >
        <Paper
          sx={role === 'user' ? MESSAGE_BOX_STYLES_USER : MESSAGE_BOX_STYLES_ASSISTANT}
        >
          <Typography
            color={role === 'user' ? 'primary.contrastText' : 'text.primary'}
            component="div"
            dangerouslySetInnerHTML={{ __html: markedMessage }}
            sx={{
              wordBreak: 'break-word',
              '& p': {
                my: 0,
              },
            }}
            variant="body2"
          />
          {isStreaming && (
            <Typography component="div" variant="body2">
              &hellip;
            </Typography>
          )}
        </Paper>
        <Box
          display="flex"
          justifyContent="space-between"
          mt={0.5}
          px={2}
        >
          {role === 'assistant' && threadId && isLastMessageItem && handleRateMessage && handleRetryMessage && (
            <Box>
              <IconButton
                onClick={handleCopy}
                size="small"
              >
                <LuCopy />
              </IconButton>
              <IconButton
                onClick={() => {
                  if (userRated !== true) {
                    handleRateMessage(messageId, true);
                  }
                }}
                size="small"
              >
                {userRated === true ? <BiSolidLike /> : <BiLike />}
              </IconButton>
              <IconButton
                onClick={() => {
                  if (userRated !== false) {
                    handleRateMessage(messageId, false);
                  }
                }}
                size="small"
              >
                {userRated === false ? <BiSolidDislike /> : <BiDislike />}
              </IconButton>
              <IconButton
                onClick={() => handleRetryMessage(messageId)}
                size="small"
              >
                <FiRefreshCw />
              </IconButton>
            </Box>
          )}
          <Typography
            color="text.disabled"
            component="div"
            variant="caption"
          >
            {dateDisplay}
          </Typography>
        </Box>
      </Box>
      {role === 'user' && avatar}
    </Box>
  );
}

function MessageLoadingIndicator() {
  return (
    <Box sx={MESSAGE_BOX_STYLES}>
      <Skeleton
        height={52}
        variant="rounded"
        width="100%"
      />
    </Box>
  );
}

const CUSTOM_RENDERER = new marked.Renderer();

const MESSAGE_BOX_STYLES = {
  display: 'flex',
  alignItems: 'flex-end',
  maxWidth: { xs: '90%', md: '80%' },
  mb: 2,
};

const MESSAGE_BOX_STYLES_ASSISTANT = {
  p: 2,
  bgcolor: 'grey.200',
  borderBottomLeftRadius: 0,
};

const MESSAGE_BOX_STYLES_USER = {
  p: 2,
  bgcolor: 'primary.main',
  borderBottomRightRadius: 0,
};
