import {
  Fab,
  Tooltip,
} from '@mui/material';
import { FaMessage } from 'react-icons/fa6';
import { FiChevronDown } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

export default function ChatFab({ isChatOpen, onClick }) {
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="left"
      title={isChatOpen
        ? t('components.Chat.ChatFab.closeChat')
        : t('components.Chat.ChatFab.startChat')}
    >
      <Fab
        color="primary"
        onClick={onClick}
        sx={{
          position: 'fixed',
          bottom: '1.5rem',
          right: '1.5rem',
          zIndex: 2147483646,
          transform: 'scale(1)',
          transition: 'transform ease-in-out 250ms',
          '&:hover, &:focus': {
            transform: 'scale(1.1)',
          },
        }}
      >
        {isChatOpen ? (
          <FiChevronDown size={22} />
        ) : (
          <FaMessage size={22} />
        )}
      </Fab>
    </Tooltip>
  );
}
