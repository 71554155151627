import PropTypes from 'prop-types';
import {
  Alert,
  Skeleton,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTenant } from '@lib/hooks';

const PermissionDeniedRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function TenantModuleGuard({ children, fallback, moduleType }) {
  const { tenant, hasTenantModule } = useTenant();
  const { t } = useTranslation();

  if (!tenant) {
    return <Skeleton />;
  }
  // eslint-disable-next-line no-underscore-dangle,max-len
  if (!hasTenantModule(moduleType)) {
    if (fallback) {
      return fallback;
    }

    return (
      <PermissionDeniedRoot>
        <Alert severity="error">
          {t('components.TenantModuleGuard.message', { module: moduleType })}
        </Alert>
      </PermissionDeniedRoot>
    );
  }

  return children;
}

TenantModuleGuard.propTypes = {
  children: PropTypes.node.isRequired,
  moduleType: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string).isRequired,
  ]),
};

TenantModuleGuard.defaultProps = {
  moduleType: null,
};
