import ChatFab from '@components/Chat/ChatFab';
import { useQueryFilter } from '@lib/hooks';
import { useCallback } from 'react';
import ChatWindow from '@components/Chat/ChatWindow';

export default function Chat() {
  const [isChatOpen, setChatOpen] = useQueryFilter('layout.chatfab.open', false);

  const handleToggleChat = useCallback(() => {
    setChatOpen(!isChatOpen);
  }, [setChatOpen, isChatOpen]);

  return (
    <>
      <ChatFab
        isChatOpen={isChatOpen}
        onClick={handleToggleChat}
      />
      <ChatWindow
        isOpen={isChatOpen}
        onClose={handleToggleChat}
      />
    </>
  );
}
