/* eslint-disable no-param-reassign */
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'react-i18next';
import {
  modules,
  routes,
} from '@config';
import {
  useQueryFilter,
  useTenant,
} from '@lib/hooks';
import Chat from '@components/Chat';
import TenantModuleGuard from '@components/TenantModuleGuard';
import AuthAclGuard from '@components/AuthAclGuard';
import MainSidebar from '../MainSidebar';
import MainNavbar from '../MainNavbar';
import {
  COLLAPSED_SIDEBAR_WIDTH,
  EXPANDED_MAIN_SIDEBAR_WIDTH,
} from '../../theme';

const MainLayoutRoot = styled('div')(({
  theme,
  mainSidebarCollapsed,
  secondarySidebarCollapsed,
}) => ({
  display: 'grid',
  width: '100vw',
  height: '100vh',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '64px 1fr',
  gridColumnGap: '0px',
  gridRowGap: '0px',
  transition: '300ms',
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: `${mainSidebarCollapsed ? COLLAPSED_SIDEBAR_WIDTH : EXPANDED_MAIN_SIDEBAR_WIDTH}px 1fr`,
    // eslint-disable-next-line max-len
    // gridTemplateColumns: `${mainSidebarCollapsed ? COLLAPSED_SIDEBAR_WIDTH : EXPANDED_MAIN_SIDEBAR_WIDTH}px 1fr ${secondarySidebarCollapsed ? COLLAPSED_SIDEBAR_WIDTH : EXPANDED_SECONDARY_SIDEBAR_WIDTH}px`,
  },
}));

const ChildrenWrapper = styled('div')(({ theme }) => ({
  gridArea: '2 / 1 / 3 / 2',
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  flexDirection: 'column',
  overflowY: 'auto',
  [theme.breakpoints.up('lg')]: {
    gridArea: '2 / 2 / 3 / 3',
  },
}));

export default function MainLayout(props) {
  const { children } = props;

  const router = useRouter();
  const { status } = useSession();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { tenant } = useTenant();
  const [isMainSidebarOpen, setMainSidebarOpen] = useState(false);
  const [isSecondarySidebarOpen, setSecondarySidebarOpen] = useState(false);
  const [isMainSidebarCollapsed, setMainSidebarCollapsed] = useQueryFilter('layout.sidebar.main.collapsed', false);
  const [isSecondarySidebarCollapsed, setSecondarySidebarCollapsed] = useQueryFilter('layout.sidebar.secondary.collapsed', true);
  const isCollapsible = useMediaQuery(theme.breakpoints.up('lg'));
  const isMainSidebarCollapsedBoxed = isCollapsible && isMainSidebarCollapsed;
  const isSecondarySidebarCollapsedBoxed = isCollapsible && isSecondarySidebarCollapsed;

  const sections = useMemo(() => getSidebarSections(t, i18n, router, tenant, theme), [
    t,
    i18n,
    router,
    tenant,
    theme,
  ]);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (status !== 'authenticated') {
      router.replace(`${routes.auth.login}?return_path=${router.asPath}`);
    }
  }, [router, router.isReady, status]);

  // Main sidebar
  const handleOpenMainSidebar = useCallback(
    () => setMainSidebarOpen(true),
    [setMainSidebarOpen],
  );
  const handleCloseMainSidebar = useCallback(
    () => setMainSidebarOpen(false),
    [setMainSidebarOpen],
  );
  const handleCollapseMainSidebar = useCallback(
    () => setMainSidebarCollapsed(true),
    [setMainSidebarCollapsed],
  );
  const handleExpandMainSidebar = useCallback(
    () => setMainSidebarCollapsed(false),
    [setMainSidebarCollapsed],
  );

  // Secondary sidebar
  const handleOpenSecondarySidebar = useCallback(
    () => setSecondarySidebarOpen(true),
    [setSecondarySidebarOpen],
  );
  const handleCloseSecondarySidebar = useCallback(
    () => setSecondarySidebarOpen(false),
    [setSecondarySidebarOpen],
  );
  const handleCollapseSecondarySidebar = useCallback(
    () => setSecondarySidebarCollapsed(true),
    [setSecondarySidebarCollapsed],
  );
  const handleExpandSecondarySidebar = useCallback(
    () => setSecondarySidebarCollapsed(false),
    [setSecondarySidebarCollapsed],
  );

  if (status !== 'authenticated') {
    return null;
  }

  return (
    <MainLayoutRoot
      mainSidebarCollapsed={isMainSidebarCollapsed}
      secondarySidebarCollapsed={isSecondarySidebarCollapsed}
    >
      <ChildrenWrapper>
        {children}
      </ChildrenWrapper>
      <MainNavbar
        onOpenMainSidebar={handleOpenMainSidebar}
        onOpenSecondarySidebar={handleOpenSecondarySidebar}
      />
      <MainSidebar
        isCollapsed={isMainSidebarCollapsedBoxed}
        isCollapsible={isCollapsible}
        onClose={handleCloseMainSidebar}
        onCollapse={handleCollapseMainSidebar}
        onExpand={handleExpandMainSidebar}
        open={isMainSidebarOpen}
        sections={sections}
      />
      {/* <SecondarySidebar
        isCollapsed={isSecondarySidebarCollapsedBoxed}
        isCollapsible={isCollapsible}
        onClose={handleCloseSecondarySidebar}
        onCollapse={handleCollapseSecondarySidebar}
        onExpand={handleExpandSecondarySidebar}
        open={isSecondarySidebarOpen}
      /> */}
      <TenantModuleGuard
        fallback={<div />}
        moduleType="ComponentTenantModuleAiAgent"
      >
        <AuthAclGuard
          fallback={<div />}
          requiredAcl={[
            'api::event.event.isEmployee',
            'api::assistant.thread.find',
          ]}
        >
          <Chat />
        </AuthAclGuard>
      </TenantModuleGuard>
    </MainLayoutRoot>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};

MainLayout.defaultProps = {
  children: null,
};

function getSidebarSections(t, i18n, router, tenant, theme) {
  // Get all the menu items from the various modules and apply
  // customizations made in the backend from the tenant.

  const items = [
    ...modules.flatMap((module) => module.menu?.getSections(t, i18n, router, tenant) ?? []),
  ];

  items.forEach((section) => {
    applyCustomizationToNavItems(section.items, i18n, tenant, theme);
  });

  return items;
}

function applyCustomizationToNavItems(items, i18n, tenant, theme) {
  items.forEach((item) => {
    if (item.children && Array.isArray(item.children)) {
      applyCustomizationToNavItems(item.children, i18n, tenant, theme);
    }

    const moduleConfig = tenant.attributes.modules.find((m) => (Array.isArray(item.type)
      // eslint-disable-next-line no-underscore-dangle
      ? item.type.includes(m.__typename)
      // eslint-disable-next-line no-underscore-dangle
      : m.__typename === item.type));

    if (!moduleConfig) {
      // Skip missing configs
      return;
    }

    if (item.attribute) {
      const navItemCustomization = moduleConfig[item.attribute];

      if (!navItemCustomization) {
        // Nav item was not localized.
        return;
      }

      // Apply label customization
      item.title = navItemCustomization[`label_${i18n.language}`] || item.title;

      if (navItemCustomization.predefinedIconName) {
        // @TODO
      }

      if (navItemCustomization.customIcon?.data) {
        const { url } = navItemCustomization.customIcon.data.attributes;

        item.icon = (
          <span
            className="svgIconMask"
            style={{
              width: 20,
              height: 20,
              maskImage: `url("${url}")`,
            }}
          />
        );
      }
    }
  });
}
