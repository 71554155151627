import {
  Dialog,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCurrentEvent } from '@modules/event/hooks';
import NoChatAvailable from '@components/Chat/NoChatAvailable';
import ChatWindowContent from './ChatWindowContent';

export default function ChatWindow({ isOpen, onClose }) {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [event] = useCurrentEvent();

  return (
    <Dialog
      fullScreen={isFullScreen}
      fullWidth
      hideBackdrop
      onClose={onClose}
      open={isOpen}
      sx={isFullScreen ? {} : {
        pointerEvents: 'none',
        '& .MuiDialog-container': {
          pointerEvents: 'none',
        },
        '& .MuiDialog-paper': {
          pointerEvents: 'auto',
          position: 'fixed',
          top: 'unset',
          left: 'unset',
          right: '1.5rem',
          bottom: '6rem',
          maxWidth: 448,
          height: 800,
          maxHeight: 'calc(85dvh - 6rem)',
          margin: 0,
        },
      }}
    >
      {event?.id ? (
        <ChatWindowContent onClose={onClose} />
      ) : (
        <NoChatAvailable onClose={onClose} />
      )}
    </Dialog>
  );
}
